<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 240.13C55.1686 235.427 68.5 217.741 68.5 217.741C68.5 217.741 85.499 240.13 128 240.13C170.501 240.13 187.5 217.741 187.5 217.741C187.5 217.741 209.935 235.427 247 240.13M49.6551 197.58L15.6868 140.249C12.1768 134.325 14.8028 126.672 21.2144 124.14L58.4307 109.444M58.4307 109.444L128.066 81.947L234.917 124.14C241.329 126.672 243.955 134.325 240.445 140.249L206.476 197.58M58.4307 109.444V72.7393C58.4307 68.9039 60.3849 65.3467 63.762 63.5177C74.6362 57.6283 100.672 45.2991 128.066 45.2991C155.459 45.2991 181.495 57.6283 192.369 63.5177C195.747 65.3467 197.701 68.9039 197.701 72.7393V107.088M77.8117 129.835L81.3851 128.294M57.9829 138.196L61.5563 136.654M98.8233 121.073L102.397 119.531M161.711 45.2997V15.8C161.711 15.3582 161.352 15 160.911 15H95.2209C94.779 15 94.4209 15.3582 94.4209 15.8V45.2997M128.066 83.8425V213.492"
      stroke="currentColor"
      stroke-width="15.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
